<template>
	<div :style="background" class="call-number">
		<div class="header">
			<img class="logo" src="../assets/image/government_icon.png" />
			<div class="title">
				<p class="zh">河北省石家庄市长安区政务服务中心</p>
				<p
					class="en"
				>Changan District Government Affairs Service Center, Shijiazhuang City, Hebei Province</p>
			</div>
		</div>
		<div class="body">
			<div class="left">
				<div class="top">
					<p>欢迎您</p>
					<p style="margin-left:10px">{{userInfo.realName}}</p>
				</div>
				<div style="margin-top:5px;margin-bottom:10px">您的工号：{{userInfo.username}}</div>
				<div class="bottom">您今天已办理{{solved}}项业务</div>
				<div class="bottom">当前等待办理业务{{waiting}}人</div>
				<div class="bottom now-number">当前：{{nowNum}}</div>
				<div class="exit">
					<div @click="quitLogin">退出登录</div>
					<img src="../assets/image/about.png" class="about" @click="openAbout" />
				</div>
			</div>
			<div class="center">
				<div class="center-top">
					<div
						class="center-top-item"
						:class="status == 1?'center-top-choice':'center-top-nochoice'"
						@click="changeList(1)"
					>待呼叫</div>
					<div
						class="center-top-item"
						:class="status == 4?'center-top-choice':'center-top-nochoice'"
						@click="changeList(4)"
					>已完成</div>
				</div>
				<div style="width:100%;height:1px;background-color:#fff"></div>
				<div class="center-header">
					<div class="header-item-left">票号</div>
					<div class="header-item-center">姓名</div>
					<div class="header-item-right">取号时间</div>
				</div>
				<div class="list" v-if="status == 1">
					<div class="item" v-for="(item,index) in waitList" :key="index">
						<div class="number" :class="item.army?'army-red':''">{{item.ticketNoFull}}</div>
						<div class="name" :class="item.army?'army-red':''">{{item.nickName}}</div>
						<div
							class="time"
							:class="item.army?'army-red':''"
						>{{item.takeTime.split(" ")[1]}}</div>
					</div>
				</div>
				<div class="list" v-else-if="status == 4">
					<div class="item" v-for="(item,index) in list" :key="index">
						<div class="number">{{item.ticketNoFull}}</div>
						<div class="name">{{item.nickName}}</div>
						<div class="time">{{item.finishTime.split(" ")[1]}}</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="item" @click="resetCall()">
					<img class="item-img" src="../assets/image/call_reset.png" />
					<span class="item-text">重新呼叫</span>
				</div>
				<div class="item" @click="suspendCall()">
					<img class="item-img" src="../assets/image/call_suspend.png" />
					<span class="item-text">暂停</span>
				</div>
				<div class="item" @click="openPingjia()">
					<img class="item-img" src="../assets/image/call_evaluate.png" />
					<span class="item-text">评价</span>
				</div>
				<div class="item" @click="welcomeCall()">
					<img class="item-img" src="../assets/image/call_welcome.png" />
					<span class="item-text">欢迎光临</span>
				</div>
				<div class="item" @click="nextCall()">
					<img class="item-img" src="../assets/image/call_next.png" />
					<span class="item-text">跳过此号</span>
				</div>
				<div class="item" @click="openCall()">
					<img class="item-img" src="../assets/image/call_number.png" />
					<span class="item-text">呼叫</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../api/index";
import { getUserInfo, removeToken } from '../utils/auth'
import { Toast } from 'vant'
export default {
	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/call_number_bg.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% auto',
				// 背景图片位置
				backgroundPosition: '0 0'
			},
			resetScoket: true,
			socket: null,
			userInfo: {},
			callDeviceMacAddr: '',
			status: 1,
			solved: 0,//已办理的数量
			waiting: 0,//等待办理的数量
			list: [],
			waitList: [],//待办列表
			callRecord: {},
			id: '',//评价的时候传的id
			timer: null,
			nowNum: '',//当前叫号
			voiceTimer: null,//点击评价之后调获取list接口后的数组
			audio: null,
			code: 108,
			isClear: false,
			dialogVisible: false,

		}
	},
	mounted () {
		let macAddr = localStorage.getItem("macAddr");
		this.initWebSocket(macAddr)
		this.callDeviceMacAddr = macAddr

		let info = getUserInfo()
		this.userInfo = JSON.parse(info)
		this.reset()
		this.getInBusinessRecord()

		// this.voicePlay();

	},
	destroyed () {
		this.resetScoket = false;
		this.socket?.close()
		clearInterval(this.timer)
		this.timer = null
		clearInterval(this.voiceTimer)
		this.voiceTimer = null
	},
	watch: {
		waitList: {
			handler (newVal, oldVal) { // newVal 和 oldVal长度可能相等
				// console.log("监听数据", '----' + newVal.length, '=======' + oldVal.length, this.code, this.isClear)
				if (newVal.length === 1 && oldVal.length === 0 && this.isClear && this.status == 1 && this.code !== 108) {
					this.voicePlay();
				}
				//客户取号 code:102 呼叫新号 code:103 跳过次号 code:104  业务已完成 code:108
				this.isClear = newVal.length === 0 && (this.code === 108 || this.code === 102 || this.code === 104)
				// console.log("监听数据--", this.isClear)
			},
			deep: true,
		},
		dialogVisible (newVal) {
			if (!newVal) {
				this.voicePause()
			}
		}
	},
	methods: {
		closeDial () {
			this.voicePause()
			this.dialogVisible = false
		},
		suportNotification () {
			if (window.Notification) {
				console.log("支持Notification")
				if (window.Notification.permission) {
					console.log("window.Notification.permission", window.Notification.permission)
					this.showNotificationMessage();
				}
			} else {
				console.log("不支持Notification")
			}
		},
		async showNotificationMessage () {
			await new Promise((reslove) => { setTimeout(() => { reslove() }, 1000) })
			window.Notification.requestPermission(function (status) {
				console.log("status", status)
				if (status === "granted") {
					new window.Notification("您有一条新消息请及时处理", {
						body: "",
						icon: "../assets/logo.png"
					})
				}
			})
		},
		//语音播报
		voicePlay () {
			//通知
			this.suportNotification()
			//弹窗
			this.dialogVisible = true
			//声音
			if (this.voiceTimer) {
				clearInterval(this.voiceTimer);
				this.voiceTimer = null;
			}
			this.voiceTimer = setInterval(() => {
				let url = "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " + encodeURI("您有一条待办业务，请及时处理");
				this.audio = new Audio(url);
				this.audio.src = url;
				this.audio.m
				this.audio.play();//播放阅读
			}, 8000)
		},
		voicePause () {
			if (this.audio) {
				if (this.voiceTimer) {
					clearInterval(this.voiceTimer);
					this.voiceTimer = null;
				}
				if (!this.audio.paused) {
					this.audio.pause();
				}
			}
		},
		//跳转到关于页面
		openAbout () {
			this.$router.push({
				name: 'AboutInfo',
			})
		},
		//点击呼叫
		openCall () {
			this.voicePause()
			this.callTicket()
		},
		//点击评价
		openPingjia () {
			this.voicePause()
			this.onEval()
		},
		//跳过此号
		nextCall () {
			this.voicePause()
			this.skipTicket()
		},
		//暂停服务
		suspendCall () {
			this.voicePause()
			this.pauseService()
		},
		//切换列表
		changeList (type) {
			this.voicePause()
			this.status = type
			this.code = 108;
			if (type == 1) {//待呼叫列表
				this.getCallList()
			} else {//已完成列表
				this.getCallListFinish()
			}

		},
		//重新呼叫
		resetCall: async function () {

			this.voicePause()
			const res = await api.repeatCall(this.callDeviceMacAddr)
			if (res.code == 200) {
				this.$toast({
					message: '点击成功',
					position: 'middle'
				});
			} else {
				this.$toast({
					message: res.msg,
					position: 'middle'

				});
			}
		},
		//欢迎光临
		welcomeCall: async function () {
			this.voicePause()
			const res = await api.welcome(this.callDeviceMacAddr)
			Toast(res.msg)
		},
		//正在办理的业务
		getInBusinessRecord: async function () {
			const res = await api.getInBusinessRecord(this.callDeviceMacAddr);
			if (res.code == 200) {
				let num = res.data?.ticketNoFull ?? ''
				let army = res.data?.army ?? false
				let txt = ""
				if (army) {
					txt = "（军人）"
				} else {
					txt = ""
				}
				this.nowNum = num + txt
			}
		},
		//叫号器 - 代办列表
		getCallList: async function () {
			this.waitList = []
			const res = await api.getCallList(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.waitList = res?.data ?? []
				this.waiting = this.waitList.length
			}
		},
		//叫号器 - 已办列表
		getCallListFinish: async function () {
			this.list = []
			const res = await api.getCallListFinish(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.list = res?.data ?? []
				this.solved = this.list.length
			}
		},
		//叫号器 - 叫号
		callTicket: async function () {
			const res = await api.callTicket(this.callDeviceMacAddr);
			if (res.code == 200) {
				// this.nowNum = res.data?.ticketNoFull ?? ''
				this.reset()
			} else {
				Toast(res.msg)
			}
			this.getInBusinessRecord()
		},
		//叫号器 - 评价
		onEval: async function () {
			const res = await api.onEval(this.callDeviceMacAddr);
			if (res.code == 200) {
				Toast('请提醒用户评价')
				this.reset()
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}

		},
		//跳过此号
		skipTicket: async function () {
			const res = await api.skipTicket(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.reset()
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}
		},
		//暂停服务
		pauseService: async function () {
			this.nowNum = ''
			const res = await api.pauseService(this.callDeviceMacAddr);
			if (res.code == 200) {
				Toast('点击成功')
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}
		},
		//叫号器 - 退出登录
		logout: async function () {
			const res = await api.logout(this.callDeviceMacAddr);
			if (res.code === 200) {
				this.$router.go(-1)
				//退出登录成功后
				removeToken()
			}
		},
		quitLogin () {
			this.logout()
		},
		reset () {
			this.getCallList()
			this.getCallListFinish()

		},
		initWebSocket (macAddr) {
			this.socket = new WebSocket("ws://caq.etuic.com:8093/eval-api/ws/queue/" + macAddr)
			this.socket.onopen = this.socketOnOpen
			this.socket.onmessage = this.socketOnMessage;
			this.socket.onclose = this.socketOnClose;
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.socket?.send(macAddr);
			}, 180000)
		},
		socketOnOpen () {
			// console.log("连接建立成功");
		},
		socketOnMessage (res) {
			console.log("后台传递数据", res);
			if (/^客户端/.test(res.data) || /^设备/.test(res.data)) {
				return
			}
			let json = JSON.parse(res?.data ?? '{}')
			let code = json.code;
			this.code = code
			//客户取号 code:102 呼叫新号 code:103 跳过次号 code:104  业务已完成 code:108
			if (code === 102 || code === 103 || code === 104 || code === 108) {
				if (code === 104) {
					this.code = 108
				}
				this.reset()
			} else if (code === 101 || code === 110) { //平台设置设备 code:101 叫号机退出登录 code:110
				removeToken()
				this.$router.replace({
					name: 'DeviceConnect',
					query: {
						refresh: true
					}
				})
			} else if (code === 112) {//给led屏发送长链接
				let ip = json.ip
				let msg = json.msg

			}
		},
		socketOnClose () {
			if (this.resetScoket) {
				this.initWebSocket(this.callDeviceMacAddr);
			}
		},
		socketSendMessgae (mes) {
			let message = JSON.stringify(mes);
			this.socket?.send(message);
		},
	}
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
	padding: 15px 20px;
}
::v-deep .el-dialog__header {
	padding: 10px 20px 0;
}
.call-number {
	height: 100%;
	.header {
		display: flex;
		flex: 0 0 40px;
		flex-direction: row;
		align-items: center;
		margin-left: 20px;
		padding-top: 10px;
		box-sizing: border-box;
		.logo {
			width: 1.36rem;
			height: 1.36rem;
		}
		.title {
			padding-left: 0.187rem;
			.zh {
				color: #fff;
				font-size: 14px;
			}
			.en {
				color: #fff;
				padding-top: 5px;
				font-size: 10px;
			}
		}
	}
	.body {
		flex: 1;
		display: flex;
		height: calc(100% - 70px);
		flex-direction: row;
		.left {
			flex: 0 0 110;
			color: #fff;
			font-size: 12px;
			padding-left: 20px;
			padding-right: 10px;
			position: relative;
			.top {
				display: flex;
				flex-direction: row;
				padding-top: 30px;
				box-sizing: border-box;
			}
			.bottom {
				font-size: 12px;
				padding-top: 5px;
				box-sizing: border-box;
			}
			.now-number {
				color: red;
			}
			.exit {
				position: absolute;
				display: flex;
				flex-direction: row;
				bottom: 10px;
				left: 20px;
				.about {
					display: block;
					margin-left: 10px;
					height: 20px;
					width: 20px;
				}
			}
		}
		.center {
			position: relative;
			flex: 1;
			background-color: #fff;
			border-radius: 4px;
			border: 1px solid #fff;
			margin-top: 15px;
			margin-bottom: 15px;
			width: 100%;
			height: calc(100% - 15px);
			overflow: hidden;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			.center-top {
				position: relative;
				display: flex;
				flex-direction: row;
				flex: 0 0 30px;
				line-height: 30px;
				width: 100%;
				font-size: 12px;
				color: #000;
				.center-top-choice {
					background-color: #fff;
					color: #2a87cd;
				}
				.center-top-nochoice {
					background-color: #2b88ce;
					color: #fff;
				}
				.center-top-item {
					flex: 1;
					height: 100%;
					text-align: center;
				}
			}
			.center-header {
				display: flex;
				flex: 0 0 30px;
				flex-direction: row;
				height: 30px;
				line-height: 30px;
				width: 100%;
				font-size: 12px;
				color: #fff;
				background-color: #2b88ce;
				.header-item-left {
					flex: 0 0 30%;
					text-align: center;
				}
				.header-item-center {
					flex: 0 0 30%;
					text-align: center;
				}
				.header-item-right {
					flex: 0 0 40%;
					text-align: center;
				}
			}
			.list {
				flex: 1;
				background-color: #fff;
				font-size: 12px;
				color: #0193f0;
				overflow: auto;
				height: 100%;
				box-sizing: border-box;
				.item {
					position: relative;
					display: flex;
					flex-direction: row;
					padding: 5px 0;
					box-sizing: border-box;
					border-bottom: 1px dashed #c3c3c3;
					.number {
						flex: 0 0 30%;
						text-align: center;
					}
					.name {
						flex: 0 0 30%;
						text-align: center;
					}
					.time {
						flex: 0 0 40%;
						text-align: center;
					}
				}
			}
		}
		.right {
			flex: 0 0 150px;
			display: flex;
			width: calc(100% - 10px);
			flex-direction: row;
			margin-left: 10px;
			height: 100%;
			padding: 10px 0;
			box-sizing: border-box;
			flex-wrap: wrap;
			.item {
				width: 65px;
				height: 52px;
				background-color: #fff;
				border-radius: 4px;
				margin-top: 5px;
				margin-right: 10px;
				color: #0193f0;
				box-sizing: border-box;
				font-size: 12px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.item-img {
					width: 18px;
					height: 18px;
					margin-bottom: 5px;
				}
			}
		}
	}
}
.about-input {
	color: #000;
	margin-top: 10px;
}
.army-red {
	color: #f44336;
}
</style>
<style >
.van-toast--text {
	width: auto !important;
}
</style>
